jQuery(document).ready(function ($) {

    function displayAndLoadImages() {
        $('.o-post-hide').slice(0, 6).each(function() {
            that = $(this);
            img = that.find('.js-post-img');

            that.removeClass('o-post-hide');

            img.css("background-image", 'url(' + img.data('src') + ')');
            img.removeAttr('data-src');
        });
    }

    function hideLoadBtn() {
        if ($('.o-post-hide').length < 1) {
            $('.js-button-load-more').fadeOut();
        }
    }

    displayAndLoadImages();
    hideLoadBtn();


    $('.js-button-load-more').on('click', function() {
        $.when(displayAndLoadImages()).then(hideLoadBtn());
    });

});
