jQuery(document).ready(function ($) {

    var navTrigger = document.getElementsByClassName('js-nav-sm__trigger')[0],
        nav = document.getElementsByClassName('js-nav-sm__menu')[0],
        body = document.getElementsByTagName('body')[0],
        navClose = document.getElementsByClassName('js-close-menu')[0],
        logoMobile = document.getElementsByClassName('js-logo-mobile')[0],
        menuMobile = document.getElementsByClassName('js-menu-mobile')[0];

    navTrigger.onclick = function() {
        nav.classList.toggle('D(b)!');
        body.classList.toggle('Ov(h)!');
        logoMobile.classList.add('D(b)!');
        menuMobile.classList.add('c-menu-mobile--is-open');
    };

    navClose.onclick = function() {
        nav.classList.toggle('D(b)!');
        body.classList.toggle('Ov(h)!');
    };

});