/* =============================================
 ===============================================
 DISPLAY MESSAGE IN TOP BANNER WITH A 5S INTERVAL
 ===============================================
 ============================================= */
jQuery(document).ready(function($) {

    $( document ).ready(function() {
        siteUri = $('body').attr('data-uri');

        window.setInterval(function(){

            var messageVisible = $('.js-topBanner__text:visible'),
                messageToShow = messageVisible.first().next('.js-topBanner__text');

            messageVisible.first().fadeOut();

            if (messageToShow.length === 1) {
                messageToShow.fadeIn();
            } else {
                $('.js-topBanner__text').first().fadeIn();
            }

        }, 5000);



    /* =============================================
     ===============================================
     SHOW INPUT SEARCH FORM
     ===============================================
     =============================================
    $('.js-trigger-calendar').on('click', function() {

        $('.js-target-calendar').slideToggle('fast');

        var calendars = $('.flatpickr-calendar'),
            calendarWidth = calendars.first().width(),
            that = $(this),
            positionTrigger = that.position(),
            heightTrigger = that.height();

        calendars.css({
            left: positionTrigger.left,
            top: positionTrigger.top + heightTrigger
        });

        $('.flatpickr-calendar:eq(1)').css('left', calendarWidth);

        calendars.toggleClass('open');
    });
     */


        $('.js-trigger-type').on('click', function() {
            $('.js-target-type').slideToggle('fast');
        });

        $('.js-trigger-number').on('click', function() {
            $('.js-target-number').slideToggle('fast');
        });


    /* =============================================
     ===============================================
     SEARCH FORM : REPLACE CATEGORY LABEL INPUT BY SELECTED VALUE
     ===============================================
     ============================================= */

        $('.js-type-temp').on('click', function() {

            // if( $('body').hasClass('page-id-14') && $('.facetwp-facet-type_products').find('.facetwp-checkbox').length === 1
            // ) {
            //     // $('.facetwp-facet-type_products').find('.facetwp-checkbox').trigger('click');
            //     $('.js-search-submit').attr('disabled', 'disabled');

            //     window.setTimeout(function() {
            //         $('.js-search-submit').prop("disabled", false);
            //     }, 2500);
            // }

            var textSelected = $(this).text();
            var typeChoice = $(this).next('input[name="cat_id"]').val();
            var triggerTypeText = $('.js-trigger-type').find('.js-trigger-type-text');

            $('.js-type-temp').not(this).removeClass('js-type-temp-selected');
            $(this).addClass('js-type-temp-selected');

            triggerTypeText.text(textSelected);

            
            $('input[name="triggerType"]').val(textSelected);
            $('input[name="triggerTypeId"]').val(typeChoice);

            $('.js-target-type').slideToggle('fast');

        });



    /* =============================================
     ===============================================
     SEARCH FORM :CREATE DATE LABEL AND INSERT INPUT DATE BY SELECTED DATE
     ===============================================
     ============================================= */

        // $('.js-target-calendar').on("DOMSubtreeModified",function(){
            // $('.facetwp-date-min').attr('id', 'facetwp-date-min');
            // $('.facetwp-date-max').attr('id', 'facetwp-date-max');

            // if ($('.calendar-max-label').length === 0) {
            //     $('.js-target-calendar').prepend('<label class="calendar-max-label o-search__trigger Pos(r)" for="facetwp-date-max"><span class="js-calendar-max-label-text">Date de fin</span><img class="Pstart(5px) D(ib) H(9px) Va(m) Pos(a)--xsmd End(5px)--xsmd H(10px)--xsmd  End(5px)!--sm H(10px)!--sm" src="'+siteUri+ '/wp-content/themes/dr-theme-la-grande-ourse/static-assets/pictos/arrow.png" alt="arrow"></label>');
            // }

            // if ($('.calendar-min-label').length === 0) {
            //     $('.js-target-calendar').prepend('<label class="calendar-min-label o-search__trigger Pos(r)" for="facetwp-date-min"><span class="js-calendar-min-label-text">Date de début</span><img class="Pstart(5px) D(ib) H(9px) Va(m) Pos(a)--xsmd End(5px)--xsmd H(10px)--xsmd  End(5px)!--sm H(10px)!--sm" src="'+siteUri+ '/wp-content/themes/dr-theme-la-grande-ourse/static-assets/pictos/arrow.png" alt="arrow"></label>');
            // }

        // });

         // $('.js-target-calendar').on("change",function(){

         //        moment.locale('fr');

         //        var dateMinValue = $('.facetwp-date-min').val(),
         //            dateMin = new Date(dateMinValue),
         //            dateMinFormatted = moment(dateMin).format('ll'),
         //            dateMaxValue = $('.facetwp-date-max').val(),
         //            dateMax = new Date(dateMaxValue),
         //            dateMaxFormatted = moment(dateMax).format('ll');

         //        if (
         //            $('.calendar-min-label').text() != dateMinFormatted && $('.facetwp-date-min').val()
         //        ) {
         //            $('.calendar-min-label').find('.js-calendar-min-label-text').text(dateMinFormatted);
         //        }

         //        if (
         //            $('.calendar-max-label').text() != dateMaxFormatted && $('.facetwp-date-max').val()
         //        ) {
         //            $('.calendar-max-label').find('.js-calendar-max-label-text').text(dateMaxFormatted);
         //        }


         //    });

    /*$('.js-target-calendar').on("change",function(){

        moment.locale('fr');

        if ($('.flatpickr-calendar:eq(2)').find('.flatpickr-day.selected').length != 0) {
            var dateMinValue = $('.flatpickr-calendar:eq(2)').find('.flatpickr-day.selected').attr('aria-label'),
                dateMin = new Date(dateMinValue),
                dateMinFormatted = moment(dateMin).format('ll');
        } else {
            $('.calendar-min-label').find('.js-calendar-min-label-text').text('...');
        }

        if ($('.flatpickr-calendar:eq(3)').find('.flatpickr-day.selected').length != 0) {
            var dateMaxValue = $('.flatpickr-calendar:eq(3)').find('.flatpickr-day.selected').attr('aria-label'),
                dateMax = new Date(dateMaxValue),
                dateMaxFormatted = moment(dateMax).format('ll');
        } else {
            $('.calendar-max-label').find('.js-calendar-max-label-text').text('...');
        }

        if ( $('.calendar-min-label').text() != dateMinFormatted ) {
            $('.calendar-min-label').find('.js-calendar-min-label-text').text(dateMinFormatted);
        }


        if ( $('.calendar-max-label').text() != dateMaxFormatted ) {
            $('.calendar-max-label').find('.js-calendar-max-label-text').text(dateMaxFormatted);
        }

    });*/

    /* =============================================
     ===============================================
    INCREMENT / DECREMENT NUMBER OF PERSONS IN SEARCH FORM
     ===============================================
     ============================================= */


        $(".js-btn-numberperson").on('click', function(){

            var numberPersonNode = $('.js-number-text');
            var numberPersonValue = parseInt(numberPersonNode.text());
            var textNumberPerson = $('.js-trigger-number-text');
            var textTraveller = $('.js-traveller');
            var numberPersonValueSelected = 0;

            if ($(this).hasClass('js-increment')) {

                numberPersonValueSelected = (parseInt(numberPersonValue)+1);

                numberPersonNode.text(numberPersonValueSelected);

                if (numberPersonValueSelected > 1) {
                    textTraveller.text(' voyageurs');
                }

            } else if (numberPersonValue>=1) {

                numberPersonValueSelected = (parseInt(numberPersonValue)-1);
                numberPersonNode.text(numberPersonValueSelected);
                if (numberPersonValueSelected <= 1) {
                    textTraveller.text(' voyageur');
                }

            }

            if ( numberPersonValueSelected === undefined || numberPersonValueSelected === 0) {
                textNumberPerson.text('Pas de voyageur');
            }
            else if ( numberPersonValueSelected === 1 ) {
                textNumberPerson.text(numberPersonValueSelected + ' voyageur');
            }
            else {
                textNumberPerson.text(numberPersonValueSelected + ' voyageurs');
            }

            
            $('input[name="triggerPerson"]').val(numberPersonValueSelected);
        });


    /* =============================================
     ===============================================
     CALLED IN THE SUBMIT BTN FOR THE SEARCH
     ===============================================
     ============================================= */

     // $('.js-search-submit').on('click', function() {
     //        var dataType = $('input[name="triggerType"]').val();
     //        var dataDate = $('input[name="triggerDate"]').val();
     //        var dataTraveller = $('input[name="triggerTraveller"]').val();
     //        window.location.href = siteUri + '/search-results/';
     //    });

        
    /* =============================================
     ===============================================
     FADE OUT OUTER TEXT WHEN SCROLL HITS THE FOOTER
     ===============================================
     ============================================= */
    
            if (window.matchMedia("(min-width: 600px)").matches) {
                $(window).on('scroll', function() {
                    var bottomHeight = $('.js-footer').innerHeight(),
                        documentHeight = $(document).innerHeight(),
                        contentHeight = documentHeight - bottomHeight,
                        scrollBottomPos = $(window).scrollTop() + window.innerHeight,
                        elementsToFix =  $('.js-absolute--scrollBottom');

                    if (scrollBottomPos >= contentHeight) {
                        elementsToFix.fadeOut();
                    } else {
                        elementsToFix.fadeIn();
                    }
                });
            }
    

    /* -------------------------------------------
     ==============================================

     MODAL WINDOW

     ==============================================
     ------------------------------------------- */


        function displayOpacityBg(OpacityBg) {
            if (window.matchMedia("(min-width: 1100px)").matches) {
                OpacityBg.fadeIn();
            }
        }

        function removeOpacityBg(OpacityBg) {
            if (window.matchMedia("(min-width: 1100px)").matches) {
                OpacityBg.fadeOut();
            }
        }

        function displayModal(modal) {
            modal.fadeIn();
        }

        function closeModal(modal) {
            modal.fadeOut();
        }

        $('.js-trigger-modal-window').on('click', function(){
            var dataTarget = $(this).data('target'),
                targetSelector = $('.'+dataTarget);
                OpacityBg = $('.js-black-opacity');

            displayModal(targetSelector);
            displayOpacityBg(OpacityBg);

            if (targetSelector.hasClass('js-modal-cart')) {

                $('body').css('overflow', 'hidden');
                if (window.matchMedia("(min-width: 1100px)").matches) {
                    $('body').animate({
                        'margin-left': '-300px'
                    }, 500);
                    $('.js-text-right').animate({
                        'right': '325px'
                    }, 500);
                    $('.js-text-left').animate({
                        'left': '-325px'
                    }, 500);
                    $("html, body").animate({ scrollTop: 0 }, "slow");

                } else {
                    $('.js-logo-mobile').addClass('D(b)!');
                    if ($('.c-menu-mobile--is-open').length === 0) {
                        $('.js-menu-mobile').addClass('c-menu-mobile--is-open');
                    }
                }


            }
        });

        $('.js-close-modal-window').on('click', function(){
            var modal = $(this).parents('.js-modal-window').first();
            var OpacityBg = $('.js-black-opacity');

            closeModal(modal);
            removeOpacityBg(OpacityBg);

            if (modal.hasClass('js-modal-cart')) {
                $('body').css('overflow', 'auto');
                if (window.matchMedia("(min-width: 1100px)").matches) {
                    $('body').animate({
                        'margin-left': '0px'
                    }, 500);
                    $('.js-text-right').animate({
                        'right': '25px'
                    }, 500);
                    $('.js-text-left').animate({
                        'left': '25px'
                    }, 500);
                }
            }

        });


        if ( $('.js-modal-cart-product-added').length != 0 ) {

            var targetSelector = $('.js-modal-cart');
                OpacityBg = $('.js-black-opacity');

            displayModal(targetSelector);
            displayOpacityBg(OpacityBg);


            $('body').css('overflow', 'hidden');
            if (window.matchMedia("(min-width: 1100px)").matches) {
                $('body').animate({
                    'margin-left': '-300px'
                }, 500);
                $('.js-text-right').animate({
                    'right': '325px'
                }, 500);
                $('.js-text-left').animate({
                    'left': '-325px'
                }, 500);
                $("html, body").animate({ scrollTop: 0 }, "slow");

            } else {
                $('.js-logo-mobile').addClass('D(b)!');
                if ($('.c-menu-mobile--is-open').length === 0) {
                    $('.js-menu-mobile').addClass('c-menu-mobile--is-open');
                }
            }
        }


    /* -------------------------------------------
     ==============================================

     SWIPER

     ==============================================
     ------------------------------------------- */

        var swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });


    /* -------------------------------------------
     ==============================================

     DISPLAY CALENDAR IN PON CLICK BTN

     ==============================================
     ------------------------------------------- */
    var calendarFrontEnd = $('.js-calendar-product-target');

    window.setTimeout(function() {
        calendarFrontEnd.hide();
    }, 500);

    $('.js-calendar-product-trigger').on('click', function(event) {
        calendarFrontEnd.slideToggle();
        event.stopPropagation();
    });
    // $('body').on('click',function(event) {
    //     calendarFrontEnd.slideUp();
    //     event.stopPropagation();
    // });
    $(document).mouseup(function (e){
        
        if (calendarFrontEnd.has(e.target).length === 0) {
            calendarFrontEnd.slideUp();
        }
    //     event.stopPropagation();
    });

    /* -------------------------------------------
     ==============================================

     CHANGE PRICE DISPLAYED BASED ON CALENDAR PRICE

     ==============================================
     ------------------------------------------- */

    // $('.wc-bookings-booking-cost').on("DOMSubtreeModified",function(){
    //     var priceUpdated = $(this).find('.woocommerce-Price-amount').text();

    //     $('.price .woocommerce-Price-amount').text(priceUpdated);
    // });

    // var Months = [
    //     'janv.',
    //     'fév.',
    //     'mars',
    //     'avril',
    //     'mai',
    //     'juin',
    //     'juillet',
    //     'aout',
    //     'sept.',
    //     'oct.',
    //     'nov.',
    //     'déc.'
    // ];

    // $('#wc-bookings-booking-form').on("DOMSubtreeModified",function(){
    //     if( $('.bookable-range').length != 0 ) {

    //         window.setTimeout(function() {
    //             $('.single_add_to_cart_button').attr('disabled', false);
    //             $('.single_add_to_cart_button').text('réserver');
    //         }, 1000);

    //         var minMonthIndex = $('.bookable-range').first().data('month'),
    //             minMonth = String(Months[minMonthIndex]),
    //             minDay =  String($('.bookable-range').first().find('.ui-state-default').text()),
    //             minDate = minDay + ' ' + minMonth;

    //         var maxMonthIndex = $('.bookable-range').last().data('month'),
    //             maxMonth = String(Months[maxMonthIndex]),
    //             maxDay =  String($('.bookable-range').last().find('.ui-state-default').text());
    //             maxDate = maxDay + ' ' + maxMonth;

    //         var textToReplace = $('.js-calendar-product-dates').text(),
    //             textToInsert = minDate + ' - ' + maxDate;

    //         if (textToReplace !== textToInsert) {
    //             $('.js-calendar-product-dates').text(textToInsert);
    //         }
    //     } else {
    //         $('.single_add_to_cart_button').attr('disabled', true);
    //         $('.single_add_to_cart_button').text('sélectionnez une date');
    //     }
    // });

    /* -------------------------------------------
     ==============================================

     GET THE DEPOSIT AMOUNT IN CHECKOUT PAGES

     ==============================================
     ------------------------------------------- */
    // if ($('.woocommerce-checkout-review-order-table').length != 0) {
    //     window.setTimeout(function() {
    //         var amountDeposit = $('.woocommerce-checkout-review-order-table').find('.variation-Montantdelacompte').find('.woocommerce-Price-amount');

    //         var amountDepositTotal = 0;

    //         amountDeposit.each(function() {
    //             amountDepositTotal += parseInt($(this).text().replace(/\D/g,''));
    //         });

    //         var amountDepositTotalDecimal = String(amountDepositTotal).slice(0, -2) + '.' + String(amountDepositTotal).substr(-2) + '€';


    //         $('.js-deposit-amount-target').text(amountDepositTotalDecimal);
    //     }, 500);
    // }

    /* -------------------------------------------
     ==============================================

     DISPLAY LOGO AND BACKGROUND MENU MOBILE ON SCROLL

     ==============================================
     ------------------------------------------- */
    $(window).on('scroll', function () {
        if ($(document).scrollTop() >= 20) {
            $('.js-logo-mobile').addClass('D(b)!');
            if ($('.c-menu-mobile--is-open').length === 0) {
                $('.js-menu-mobile').addClass('c-menu-mobile--is-open');
            }
        } else {
            $('.js-menu-mobile').removeClass('c-menu-mobile--is-open');
            $('.js-logo-mobile').removeClass('D(b)!');
        }


    });

    /* -------------------------------------------
     ==============================================

        HIDE IMG CART

     ==============================================
     ------------------------------------------- */
    $('.c-img-review-order').first().hide();

    /* -------------------------------------------
     ==============================================

     BTN TOP SCROLL

     ==============================================
     ------------------------------------------- */

    $('.js-scroll-top').on('click', function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    if ($('.js-contact-query').length !== 0 &&  $('.page-id-107').length !== 0) {
        $('.js-trigger-modal-contact').trigger('click');
    }


    /* =============================================
     ===============================================
     REMOVE ZOOM INPUT TEXT MOBILE
     ===============================================
     ============================================= */

        $(window).load(function() {
            $("input[type=text], textarea").on({
                'touchstart': function () {
                    zoomDisable();
                }
            });
            $("input[type=text], textarea").on({
                'touchend': function () {
                    setTimeout(zoomEnable, 1000);
                }
            });

            function zoomDisable() {
                $('head meta[name=viewport]').remove();
                $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />');
            }

            function zoomEnable() {
                $('head meta[name=viewport]').remove();
                $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1" />');
            }
        });

        /* =============================================
     ===============================================
     REMOVE PINPOINT CSS
     ===============================================
     ============================================= */
        $('link[href*="wp-content/plugins/dopbsp/templates/default/css/jquery.dop.frontend.BSPCalendar.css"]').remove();


        /* =============================================
     ===============================================
     CALENDAR FRONT-PAGE PARAMETER
     ===============================================
     ============================================= */
        
        flatpickr.l10ns.default.firstDayOfWeek = 1;
        flatpickr.localize(flatpickr.l10ns.fr);
        $('.dayContainer').append('<div class="msg_calendar">Choisir une date de début</div>');
        flatpickr(".calendar-min-label",
            {
                minDate: "today",
                dateFormat: "j M Y",
                mode: "range",
                onOpen: function() {
                    $('.msg_calendar').remove();
                    $('<div class="msg_calendar Fz(9px) Lts(1.8px) o-font-bourtonbase Fz(12px)--xsmd Fz(9px)!--xs">Choisir une date de début</div>').insertAfter('.flatpickr-months');
                },     
                onChange: function(selectedDates, dateStr,instance) {
                    var msg = $('.msg_calendar');
                    $('.calendar-min-label').val(dateStr);
                    $('input[name="triggerDate"]').val(dateStr);
                    $('input[name="triggerDateDisplay"]').val(dateStr);
                    $('.dayContainer').append('<div class="button_clear">Effacer</div>');
                    msg.empty();
                    msg.html('Choisir une date de fin');
                    msg.hide()
                    msg.fadeIn(500);
                    $('.button_clear').on('click', function() {
                        var fpr = $('.flatpickr-day');
                        fpr.removeClass('selected');
                        fpr.removeClass('startRange');
                        fpr.removeClass('endRange');
                        fpr.removeClass('inRange');
                        $('.calendar-min-label span').html("date de l'escapade");
                        $('input[name="triggerDate"]').val('');
                        $('input[name="triggerDateDisplay"]').val('');
                    });

                }
            }
        );
        
        /* =============================================
     ===============================================
     TO MOVE MESSAGE-ERROR IN THE ORDER PAGE
     ===============================================
     ============================================= */

        $('form.checkout.woocommerce-checkout').bind('DOMSubtreeModified',function(){
            if ($('ul.woocommerce-error').length) {
                $('ul.woocommerce-error').insertAfter('.woocommerce-form-coupon');//where you want to place it
            }
        });

        
        
    });
});